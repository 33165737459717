import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide34/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide34/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide34/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide34/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide34/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      5 estrategias para mejorar la atención al cliente por teléfono
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      El servicio al cliente es fundamental para la lealtad de los consumidores,
      pero existe una brecha significativa entre lo que las empresas creen
      ofrecer y la experiencia real de los clientes. De hecho, solo el 8% de los
      consumidores cree que una empresa ofrece una buena atención.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Reconociendo que la percepción del servicio varía entre empresas y clientes,
    se destaca la importancia de alinear las expectativas y experiencias.
    <br /> <br />
    Por eso, esta guía ofrece valiosos consejos para las empresas sobre cómo
    mejorar la amabilidad en las llamadas de soporte al cliente. Además,
    aprenderás algunas técnicas básicas como habilidades de comunicación
    efectiva para garantizar una interacción más positiva.
  </p>
)

const data = {
  start: {
    support: "Guía | Atención al cliente ",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Utilizar la escucha activa.",
      },
      {
        key: 2,
        text: "Pasos para reducir el tiempo de espera.",
      },
      {
        key: 3,
        text: "Cómo promover el pensamiento crítico.",
      },
      {
        key: 4,
        text: "Uso del lenguaje positivo.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
